import React from "react";
import type { Descendant } from "slate";
import type { RenderElementProps, RenderLeafProps } from "slate-react";

import { useTextBlockContents } from "../TextBlockContext";
import { TextBlockRenderProvider, useTextBlockRendering } from "../TextBlockRenderContext";

interface TextBlockStaticProps {
  id: string;
  initialValue: Descendant[];
}

const recursivelyRenderElement = (
  element: Descendant,
  options: ReturnType<typeof useTextBlockRendering>
): JSX.Element => {
  const { renderElement, renderLeaf } = options;
  if ("text" in element) {
    return renderLeaf({
      children: element.text,
      leaf: element,
      text: {
        text: element.text
      },
      attributes: {} as RenderLeafProps["attributes"]
    });
  } else {
    const childrenMapped = element.children?.map((child, i) => (
      <React.Fragment key={i}>{recursivelyRenderElement(child, options)}</React.Fragment>
    ));
    return renderElement({
      children: childrenMapped,
      element,
      attributes: {} as RenderElementProps["attributes"]
    });
  }
};

const recursivelyRenderElements = (
  elements: Descendant[],
  options: ReturnType<typeof useTextBlockRendering>
) => {
  return elements.map((element, i) => (
    <React.Fragment key={i}>{recursivelyRenderElement(element, options)}</React.Fragment>
  ));
};

const TextBlockStatic: React.FC<TextBlockStaticProps> = ({ id, initialValue }) => {
  const textBlockRendering = useTextBlockRendering();
  const { getInitialTextBlock } = useTextBlockContents();
  const contents = recursivelyRenderElements(
    getInitialTextBlock(id) || initialValue,
    textBlockRendering
  );
  return <>{contents}</>;
};

export const renderTextBlockStatic: React.ComponentProps<
  typeof TextBlockRenderProvider
>["renderTextBlock"] = (id, initialValue) => {
  return <TextBlockStatic id={id} initialValue={initialValue} />;
};
