import { Grid, GridColumn, Text, ThemeOverride, ThemeProvider } from "@modernatx/ui-kit-react";
import React from "react";
import { Global } from "theme-ui";

import { RenderMetadata } from "@/components/RenderMetadata";
import { RenderContent } from "@/components/rsv/RenderContent";
import { ExperienceProvider } from "@/context/ExperienceContext";
import { ExitModalProvider } from "@/hooks/useExitModal";
import { BlockPageProps } from "@/types/Block";
import { Experience } from "@/utils/experience";

interface BlockPagePropsWithExperience {
  content: BlockPageProps | null;
  experience: Experience | null;
}

export const BlockPage: React.FC<BlockPagePropsWithExperience> = ({ content, experience }) => {
  const { country } = experience || {};

  const background = (
    <Global
      styles={{
        html: {
          backgroundColor: "white"
        }
      }}
    />
  );

  if (!country || !content || typeof content.theme !== "object" || !experience) {
    return (
      <ThemeProvider usingStyleInheritance={true}>
        {background}
        <Grid>
          <GridColumn
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <Text size="lg" as="p">
              {!country || !content
                ? "The site is not available in your country."
                : "A theme object is required"}
            </Text>
          </GridColumn>
        </Grid>
      </ThemeProvider>
    );
  }

  return (
    <ExperienceProvider experience={experience}>
      <ThemeProvider theme={content.theme} usingStyleInheritance={true}>
        <ThemeOverride mode={content.themeMode}>
          <Global
            styles={{
              html: {
                backgroundColor: content.backgroundColor as string,
                wordBreak: content.wordBreak
              }
            }}
          />
        </ThemeOverride>
        <ExitModalProvider exitModal={content.exitModal}>
          <RenderMetadata {...content.metadata} renderGoogleRecaptcha={true} />
          <RenderContent content={content} />
        </ExitModalProvider>
      </ThemeProvider>
    </ExperienceProvider>
  );
};
