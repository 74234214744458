import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { Descendant } from "slate";

import { BlockPage } from "@/components/BlockPage";
import { TextBlockProvider } from "@/components/TextBlock/TextBlockContext";
import { TextBlockRenderProvider } from "@/components/TextBlock/TextBlockRenderContext";
import { renderTextBlockStatic } from "@/components/TextBlock/TextBlockStatic/TextBlockStatic";
import { BlockPageProps } from "@/types/Block";
import { Experience, getExperience } from "@/utils/experience";
import { getIsProduction } from "@/utils/production";
import { fetchProfileText, getProfileTextIdsAndRemoveUndefinedValues } from "@/utils/profileText";

export const getServerSideProps: GetServerSideProps<{
  content: BlockPageProps | null;
  experience: Experience | null;
  isProduction: boolean;
  textBlocks: Record<string, Descendant[]> | null;
}> = async ({ req }) => {
  const experience = getExperience(req);
  const isProduction = getIsProduction();
  const profile = experience?.profile;
  let content: BlockPageProps | undefined;
  let textBlocks: Record<string, Descendant[]> | null;

  try {
    const contentModule = profile
      ? await import(`../../profiles${profile}`)
      : Promise.resolve(undefined);
    const ids: string[] = [];
    content = getProfileTextIdsAndRemoveUndefinedValues(contentModule.default, ids);
    textBlocks = await (profile
      ? fetchProfileText(experience.language, ids).catch(() => null)
      : Promise.resolve(null));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Invalid profile path:", e);
    return {
      notFound: true
    };
  }

  if (
    !content ||
    (isProduction && !experience?.isProduction) ||
    (isProduction &&
      !!experience?.requiresReferer.length &&
      !experience?.requiresReferer.find((referer) => req.headers.referer?.includes(referer)))
  ) {
    return {
      props: getProfileTextIdsAndRemoveUndefinedValues(
        {
          content: null,
          experience,
          isProduction,
          textBlocks: null
        },
        []
      )
    };
  }

  return {
    props: getProfileTextIdsAndRemoveUndefinedValues(
      {
        content,
        experience,
        isProduction,
        textBlocks: textBlocks
      },
      []
    )
  };
};

const NextJsBlockPage = ({
  content,
  experience,
  textBlocks
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <TextBlockProvider textBlocks={textBlocks || {}} onTextBlockChange={() => {}}>
      <TextBlockRenderProvider editing={false} renderTextBlock={renderTextBlockStatic}>
        <BlockPage experience={experience} content={content} />
      </TextBlockRenderProvider>
    </TextBlockProvider>
  );
};

export default NextJsBlockPage;
