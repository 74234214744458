import type { ThemeMode } from "@modernatx/ui-kit-react";
import EventEmitter from "events";
import React from "react";
import type { Descendant, Location } from "slate";

import type { TextBlockElement, TextBlockSelection, TextBlockText } from "./types";

interface TextBlockProviderProps {
  children?: React.ReactNode;
  onTextBlockChange: (id: string, contents: Descendant[]) => void;
  textBlocks: Record<string, Descendant[]>;
}

interface TextBlockState {
  selection: TextBlockSelection;
  selectionId: string | null;
  selectionMode: ThemeMode;
  selectionSelect: (selection: Location) => void;
  setElementProperties: (props: Partial<TextBlockElement>) => void;
  setTextProperties: (props: Partial<TextBlockText>) => void;
}

interface TextBlockContextValue {
  selectedTextBlock: HTMLElement | null;
  selectedTextBlockSet: (selectedTextBlock: HTMLElement | null) => void;
  setTextBlockState: (methods: TextBlockState) => void;
  textBlockChange: (id: string, contents: Descendant[]) => void;
  textBlocks: Record<string, Descendant[]>;
  textBlockState: React.MutableRefObject<TextBlockState>;
  textBlockStateEmitter: EventEmitter;
}

export const TextBlockContext = React.createContext<TextBlockContextValue>({
  textBlockState: {
    current: {
      selection: {
        parentElements: []
      },
      selectionId: null,
      selectionMode: "light",
      selectionSelect: () => {},
      setElementProperties: () => {},
      setTextProperties: () => {}
    }
  },
  textBlocks: {},
  textBlockChange: () => {},
  textBlockStateEmitter: new EventEmitter(),
  selectedTextBlock: null,
  selectedTextBlockSet: () => {},
  setTextBlockState: () => {}
});

export const TextBlockProvider: React.FC<TextBlockProviderProps> = ({
  children,
  onTextBlockChange,
  textBlocks
}) => {
  // We need to maintain a separate event emitter as renders over the
  // Slate component break the ability to select less fragments than
  // are currently selected
  const [textBlockStateEmitter] = React.useState(() => new EventEmitter());
  const contextInitial = React.useContext(TextBlockContext);
  const textBlockState = React.useRef<TextBlockState>({
    ...contextInitial.textBlockState.current
  });
  const setTextBlockState = React.useCallback(
    (textBlockStateNext: TextBlockState) => {
      textBlockState.current = { ...textBlockStateNext };
      textBlockStateEmitter.emit("change", textBlockState.current);
    },
    [textBlockStateEmitter]
  );
  const [selectedTextBlock, selectedTextBlockSet] = React.useState<HTMLElement | null>(null);

  return (
    <TextBlockContext.Provider
      value={{
        selectedTextBlock,
        selectedTextBlockSet,
        setTextBlockState,
        textBlockChange: onTextBlockChange,
        textBlocks,
        textBlockState,
        textBlockStateEmitter
      }}
    >
      {children}
    </TextBlockContext.Provider>
  );
};

export const useTextBlock = () => {
  const { setTextBlockState, textBlockState, textBlockStateEmitter } =
    React.useContext(TextBlockContext);
  const [state, setState] = React.useState<TextBlockState>(textBlockState.current);
  const { selection, selectionId, selectionMode } = state;

  React.useEffect(() => {
    textBlockStateEmitter.on("change", setState);
    return () => {
      textBlockStateEmitter.off("change", setState);
    };
  }, [textBlockStateEmitter]);

  return React.useMemo<TextBlockState>(() => {
    return {
      selection,
      selectionId,
      selectionMode,
      selectionSelect: (location) => {
        textBlockState.current.selectionSelect(location);
      },
      setTextProperties: (values) => {
        textBlockState.current.selection = {
          ...textBlockState.current.selection,
          ...values
        };
        textBlockState.current.setTextProperties(values);
        setTextBlockState(textBlockState.current);
      },
      setElementProperties: (values) => {
        textBlockState.current.selection = {
          ...textBlockState.current.selection,
          ...values
        };
        textBlockState.current.setElementProperties(values);
        setTextBlockState(textBlockState.current);
      }
    };
  }, [selection, selectionId, selectionMode, setTextBlockState, textBlockState]);
};

export const useTextBlockContents = () => {
  const { textBlockChange, textBlocks } = React.useContext(TextBlockContext);

  return React.useMemo(
    () => ({
      getInitialTextBlock: (id: string) => {
        return textBlocks[id];
      },
      textBlockChange
    }),
    [textBlockChange, textBlocks]
  );
};
